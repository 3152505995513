import React, { Component } from 'react';
import Img from "gatsby-image";

import styled from 'styled-components';

class Gallery extends Component {
    constructor() {
        super();

        this.state = {
            images: [],
            thumbs: []
        }
    }

    componentDidMount() {
        const { data } = this.props;
        const images = data.allImageSharp.edges;

        // if (images.length > 9) {
        //     const visImgs = images.slice(0, 9);

        //     this.setState({ images: visImgs });
        //     this.getThumbNums(images);
        // } else {
        this.setState({ images });
        // }
    }

    // getThumbNums(images) {
    //     const thumbsNum = Math.floor(images.length / 9);
    //     let thumbs = [1];

    //     for (let i = 2; i <= thumbsNum + 1; i++) {
    //         thumbs.push(i)
    //     }
    //     this.setState({ thumbs })
    // }

    // changeImages(e) {        
    //     const { data } = this.props;
    //     const images = data.allImageSharp.edges;
    //     const btnVal = e.target.value;
    //     const first = btnVal * 9;
    //     const visImgs = images.slice(first, first + 9);

    //     this.setState({ images: visImgs });
    // }

    render() {
        const { images, thumbs } = this.state;

        const gallery = images.map((img, index) => {
            return <Item key={index}><Img sizes={img.node.sizes} /></Item>
        });
        
        const thumbList = thumbs.map((t, index) => {
            return <Thumb className="galleryThumb" key={index} value={index} >{t}</Thumb>
        })

        return (
            <GalleryContainer id="gallery">
                <Header className="title-underline">Pups Gallery</Header>
                <div className="container">
                    <List>
                        {gallery}
                    </List>
                    <ThumbList>
                        {thumbList}
                    </ThumbList>
                </div>
            </GalleryContainer>
        );
    }
}

export default Gallery;

const Header = styled.h2`
    color: #014e73;
    padding: 0 20px;

    @media screen and (min-width: 1200px) {
        margin: 20px auto;
        max-width: 1200px;
    }

    &:after {
        left: 20px !important;
    }
`;

const GalleryContainer = styled.div`
    padding: 40px 0;
`;

const List = styled.ul`
    
    @media screen and (min-width: 500px) {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
    }
`;

const Item = styled.li`
    
    @media screen and (min-width: 500px) {
        width: calc(50% - 5px);
    }
    @media screen and (min-width: 700px) {
        width: calc(33.33333% - 6.66667px);
    }
`;

const ThumbList = styled.ul`
    display: flex;
    justify-content: center;
`;
const Thumb = styled.li`
    color: #b2b2b2;    
    font-size: 20px;
    font-weight: bold;
    margin: 10px 15px;

    cursor: pointer;

    &:hover,
    &:focus {
        color: #004f74;
    }
`;